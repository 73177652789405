<template>
    <v-dialog v-model="showDialog">
        <v-card>
            <v-card-title>
                Nuevo conector
            </v-card-title>
            <v-card-text>
                <v-select @change="loadMarketplaceData" label="Nombre Marketplace" :items="marketplacesList" v-model="selectMarketplace"></v-select>
            </v-card-text>
            <v-card-text style="display: flex;">
                <v-card class="my-2 mx-2" style="flex-grow: 1;"  outlined>
                    <v-card-title>
                        {{selectMarketplaceName}}
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form" v-model="valid" lazy-validation >   
                            <div v-for="(elem) in Object.keys(marketplace_data)" :key="elem">
                                <v-text-field
                                    v-model="marketplace_data[elem].value"
                                    :label="elem+' ('+marketplace_data[elem].type+')'"
                                    required
                                    :append-icon="marketplace_data[elem].type != 'string' && marketplace_data[elem].type != 'number' ? ( marketplace_data[elem].type == 'array' ? 'mdi-code-array' :'mdi-code-json') : 'mdi-code-string'"
                                ></v-text-field>
                            </div>
                        </v-form>
                    </v-card-text>
                    
                </v-card>
            </v-card-text>
            <v-card-actions>
                <v-btn class="btn-starkoms-primary mt-2" @click="sendForm()">
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    created(){
        this.$parent.$on('open-dialog-add-marketplace', () => {
            this.fetchData();
        });
    },
    data(){
        return {
            showDialog: false,
            marketplaces_availables: [],
            marketplacesList: [],
            selectMarketplace: undefined,
            marketplace_data: {},
            selectMarketplaceName: undefined,
            valid: false,
        }
    },
    methods:{
        fetchData(){
            var vm = this;
            this.axios({
                url: "marketplace/marketplaces_availables",
                method: "GET",
            }).then( response => {
                vm.marketplaces_availables = response.data;
                vm.marketplacesList = vm.marketplaces_availables.map(e => ({text: e.name, value: e.id}));
                vm.showDialog = true;
            }).catch( error => {
                console.log(error);
            })
        },
        loadMarketplaceData(){
            if(this.selectMarketplace){
                const marketplace_selected = this.marketplaces_availables.find(e => e.id == this.selectMarketplace);
                console.log('marketplace_selected', marketplace_selected);
                if(marketplace_selected){
                    this.marketplace_data = {};
                    Object.keys(marketplace_selected.marketplace_data).forEach(key => {
                        console.log('key', key, typeof marketplace_selected.marketplace_data[key]);
                        if( marketplace_selected.marketplace_data[key] instanceof Array){
                            this.marketplace_data[key] = { type: 'array' ,value: "(JSON Array) [...]"};
                            return;
                        }
                        if( typeof marketplace_selected.marketplace_data[key] === 'object'){
                            this.marketplace_data[key] = {type: 'object', value: "(JSON Object) {...}"};
                            return;
                        }
                        if( (typeof marketplace_selected.marketplace_data[key]) === 'string' || (typeof marketplace_selected.marketplace_data[key]) === 'number'){
                            this.marketplace_data[key] = { type: 'string', value: ""};
                            return;
                        }
                    });
                    this.selectMarketplaceName = marketplace_selected.name;
                    return;
                }
            }
            this.marketplace_data = {};
            this.selectMarketplaceName = undefined;
        },
        sendForm(){
            var vm = this;
            this.axios({
                url: "marketplace/marketplaces/add_store",
                method: "POST",
                data: {
                    marketplace_id: this.selectMarketplace,
                    marketplace_data: this.marketplace_data,
                }
            }).then( response => {
                vm.$bvToast.toast("Marketplace agregado con éxito", {
                    title: `Exito`,
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                vm.$emit("success");
                vm.showDialog = false;
            })
        }
    }
}
</script>